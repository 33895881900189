import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../ui/Home";
import About from "../ui/About";
import { AboutMobile } from "../ui/AboutMobile";
import { Contact } from "../ui/Contact";
import { ContactMobile } from "../ui/ContactMobile";
import { FairPlay } from "../ui/FairPlay";
import { HowToPlay } from "../ui/HowToPlay";
import { HowToPlayMobile } from "../ui/HowToPlayMobile";
import { Policy } from "../ui/Policy";
import { PolicyMobile } from "../ui/PolicyMobile";
import { TermsNConditions } from "../ui/TermsNConditions";
import { TermsNConditionsMobile } from "../ui/TermsNConditionsMobile";
import { RefundPolicy } from "../ui/RefundPolicy";
import { SubstitutionRules } from "../ui/SubstitutionRules";
import { WalletSection } from "../ui/WalletSection";
import { Faq } from "../ui/Faq";
import { ResponsibleGaming } from "../ui/ResponsibleGaming";
import { ComingSoon } from "../ui/ComingSoon";
import Legalities from "../ui/Legalities";
import FantasyPointSystem from "../ui/FantasyPointSystem";
import { ToastContainer } from "react-toastify";
import Loading from "../components/Loading";
import LoaderHelper from "../components/Loading/LoaderHelper";
import Payment from "../ui/Payment"; 
import FantasyPointSystemMobile from "../ui/FantasyPointSystemMobile";
import LegalitiesMobile from "../ui/LegalitiesMobile";
import { ResponsibleGamingMobile } from "../ui/ResponsibleGamingMobile";
import { FairPlayMobile } from "../ui/FairPlayMobile";
// import { FantasyPointSystem } from "../ui/FantasyPointSystem";

const Routing = ()=>{
    return (
        <>
        <Loading ref={ref => LoaderHelper.setLoader(ref)} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/aboutMobile" element={<AboutMobile />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contactMobile" element={<ContactMobile />} />
            <Route path="/fairPlay" element={<FairPlay />} />
            <Route path="/fairPlaymobile" element={<FairPlayMobile />} />
            <Route path="/howToPlay" element={<HowToPlay />} />
            <Route path="/howToPlayMobile" element={<HowToPlayMobile />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/policyMobile" element={<PolicyMobile />} />
            <Route path="/termsNConditions" element={<TermsNConditions />} />
            <Route path="/termsNConditionsMobile" element={<TermsNConditionsMobile />} />
            <Route path="/refund_policy" element={<RefundPolicy />} />
            <Route path="/Substitution_rules" element={<SubstitutionRules />} />
            <Route path="/walletsection" element={<WalletSection />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/responsible_gaming" element={<ResponsibleGaming />} />
            <Route path="/responsible_gamingmobile" element={<ResponsibleGamingMobile />} />
            <Route path="/coming_soon" element={<ComingSoon />} />
            <Route path="/legalities" element={<Legalities />} />
            <Route path="/legalitiesmobile" element={<LegalitiesMobile />} />
            <Route path="/fantasy_points_system" element={<FantasyPointSystem />} /> 
            <Route path="/fantasy_points_systemmobile" element={<FantasyPointSystemMobile />} /> 
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </>
      );
}


export default Routing