import { ApiConfig } from "../apiConfig/ApiConfig";
import { ApiCallPost } from "../apiConfig/ApiCall";
const HomeService = {


  getPointstest: async () => {
    const { appUrl, getPointstest } = ApiConfig;
    const url = appUrl + getPointstest;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPoints: async () => {
    const { appUrl, getPoints } = ApiConfig;
    const url = appUrl + getPoints;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPointst20: async () => {
    const { appUrl, getPointst20 } = ApiConfig;
    const url = appUrl + getPointst20;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPointsODI: async () => {
    const { appUrl, getPointsODI } = ApiConfig;
    const url = appUrl + getPointsODI;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },

  getPaymentStatus: async () => {
    const { paymentUrl, getPaymentStatus } = ApiConfig;
    const url = paymentUrl + getPaymentStatus ;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url,params, headers);

  },
 
};


export default HomeService;
