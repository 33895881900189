import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const ResponsibleGaming = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.mybattle11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>    Responsible Gaming </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li> Responsible Gaming  </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <h4 className="mb-3" ><b>Responsible Gaming </b></h4>

              <p>
               <b> 1.	</b> MyBattle11 Platform strives to ensure responsible gaming in its game of skills offerings across the Platform. However, it recognises that such games may be addictive and
              </p>
              <p>
              <b> 2.</b>	 The Public Gambling Act, 1867 (“PGA”) provides an exception for games which involve element of skill and such games are considered to be legal. Fantasy Sports play have been globally recognised as ‘Games of Skill’ wherein the players have to make their own calculated strategies to pick the players for their teams based on several factors including evaluation of historical data of the players, the viability of the teams, the current conditions of the players etc.
              </p>
              <p>
              <b>3.</b> There have been various judicial pronouncements with respect to the legality of fantasy sports leagues in India as the same are considered ‘Game of Skill’ and are currently permissible in India (in most States). 
              
              </p>
              <ul>
               <li>
               a)	Rummy was recognised as a game of skill [AIR 1968 SC 825] 
               </li>
              <li>
              b)	Fantasy Cricket was recognised as a game of skill by the Punjab and Haryana High Court in 2017 and the Rajasthan High Court in 2020 and affirmed by the Supreme Court in Avinash Mehrotra vs. State of Rajasthan & Ors. on 30.07.21. (iii) State of Kerela has permitted online Rummy as the same is considered a game of skill.
              </li>
              </ul> 

              <p>
             <b> 4.</b>	  As on date, the Specific Laws in Jurisdictions of States of Assam, Odisha, Nagaland, Sikkim, Andhra Pradesh and Telangana do not permit ‘Games of Skill’ in their territorial jurisdictions including fantasy sports.

              </p>
              <p>
             <b> 4.</b>	  The evolving legal position of the respective Concerned State regarding Games of Skill is under consideration by the Courts of India and the eligibility of users from the Concerned States is subject to final outcome of these legal challenges.

              </p>


             
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
