import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const PolicyMobile = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      {/* <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo"> 
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.mybattle11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
 
        </div>
      </header> */}
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec mt-0">
        <div class="heading">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <main id="main">
        {/* <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Privacy Policy</li>
              </ol>
            </div>
          </div>
        </section> */}
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content"> 
              <p class="lh18 pdTop15">
              <strong>1.</strong>  “MyBattle11” is a fantasy multi-gaming platform owned, operated, and offered by SPODS Technologies Private Limited (‘Company’) through its website https://mybattle11.com/ and mobile applications; collectively referred to as ‘My Batlle 11’.   
              </p>
               
               
              <p class="lh18 pdTop15">
              <strong>2. </strong>Any person utilizing MyBattle11 Platform (‘User’ or ‘you’ or ‘your’) or any of its features including but not limited to participation in the various contests, games (‘Services’) being conducted on MyBattle11 Platform shall be bound by this Privacy Policy.
              </p>
               <br/>
              <p class="lh18 pdTop15">
                <strong> 3. Contract & Acceptance </strong>
              </p>
              <p class="lh18 pdTop15">
              <strong>3.1.</strong> This Privacy Policy is in addition to the Terms & Conditions of MyBattle11 Platform at https://www.MyBattle11.com/. The complete Terms & Conditions shall be deemed to be part and parcel of the present Privacy Policy and vice versa. The provisions of the Terms & Conditions shall apply mutatis mutandis to the present Privacy Policy.
              </p>

              <p>
              <strong>3.2.</strong> This Privacy Policy elucidates the nature of the User Information collected and stored through MyBattle11 Platform and provides the purpose for which such Information is used and the extent of such use.
              
              </p>
              <p>
              <strong> 3.3.</strong> This Privacy Policy is an electronic contract compliant with Information Technology Act, 2000 and the Rules made thereunder.
              
              </p>
              <p>
              <strong>3.4. </strong> This Privacy Policy does not require any physical, electronic, or digital signature and is a legally binding document between the User and MyBattle11 Platform. By signing up on the website, installing or logging into the Mobile Applications or using any part of MyBattle11 Platform; the User gives consent to and accepts the present Privacy Policy as well as the Terms & Conditions outlined by MyBattle11 Platform and thereby consents to the collection, use, disclosure and transfer of User Information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information.

              </p>

              <br/>
              <p class="lh18 pdTop15">
                <strong> 4. User Information & Consent</strong>
              </p>
              <p>
              <strong>4.1.</strong> MyBattle11 Platform is committed to providing seamless quality playing features all the while ensuring privacy and discretion of its Users and security of User Information when interacting with MyBattle11. The Personal Information, Sensitive Personal Information and Associated Information involved in the interaction between the User and MyBattle11 Platform or User-to-User are collectively defined as ‘User Information’ and enumerated under Information Processing in this Policy.
              
              </p>
              <p>
              <strong>4.2.</strong> In order to make the best use of the Application/Website/Services/products offered on MyBattle11 Platform and enable the User’ Information to be captured accurately on the Application/Website/Services/products, it is essential that User login/register using their own correct and updated credentials. MyBattle11 Platform shall bear no responsibility or liability for any false information provided to it by Users.
              
              </p>
              <p>
              <strong>4.3.</strong> MyBattle11 Platform may ask a User to provide more information that personally identifies the User (personal information) or allows it to contact the User.
              
              </p>
              <p>
              <strong>4.4.</strong> MyBattle11 Platform will not differentiate between who is using the device to access the Application, Website or Services or products, so long as the log in/access credentials match with the User’s self-declared inputs.
              
              </p>
              <br/>
              <p class="lh18 pdTop15">
                <strong> 5. Information Processing </strong>
              </p>
              
              <p>
              The User Information that may be collected by MyBattle11 Platform at various stages of access and game play is interlinked with providing of enhanced and ready access to fantasy playing features to every User/Player. The Information collected and stored by MyBattle11 Platform can be broadly classified into 3 categories viz, (i) Personal Information, (ii) Sensitive Personal Information; and (iii) Associated Information.
              
              </p>
              <br/>  
              <p>
              <strong>5.1.</strong> Personal Information (‘PI’)
              </p>
              <p>
              The following Information that may be collected by MyBattle11 Platform shall be considered as Personal Information: -
              </p>

              <div className="ps-md-4 ps-2" >
              <p>
              <strong>5.1.1.</strong> The following Information may be collected at the stage of Registration: -
             </p>
                <ul>
                <li> 	Team Name,</li>
                <li> 	Date of birth,</li>
                <li> 	E-Mail Address,</li>
                <li> 	Mobile Number,</li>
                <li> 	Unique username / ID and password combination; and</li>
                <li> 	State</li>
                <li> 	Self-declaration of Jurisdiction by User.</li>
                </ul>
                <p> 
                The Information referred to in Clause 5.1.1. is required to create an identity of the User/Player within MyBattle11 Platform and further classified as ‘Personal Identifiable Information’.
                
                </p>
                <p>  <b>5.1.2.</b> Internal call logs with MyBattle11 Customer Care executives. </p>
                <p>  <b>5.1.3.</b> IP Address, server information & device location. </p>
                <p>  <b>5.1.4.</b> Device information such as storage, model, mobile network in case of Mobile application Users. </p>
                <p>  <b>5.1.5.</b> Game play preferences and play logs of Users.    </p>
              </div>

              <p>
               <b>5.2. </b> Sensitive Personal Information (‘SPI’)
              </p>

              <p>
              The following Information that may be collected by MyBattle11 Platform shall be considered as Sensitive Personal Information: -
              </p>

              <div className="ps-md-4 ps-2" >
                <p> <b>5.2.1.</b> Permanent Account Number;</p>
                <p> <b>5.2.2.</b> Aadhaar Card Number;</p>
                <p> <b> 5.2.3.</b> Bank Details;</p>
                <p> <b> 5.2.4.</b> Details of Mode of Preferred mode of Payment provided by the User such as Cardholder name, credit/debit card number (in encrypted form) with an expiration date, banking details, wallet details, etc. </p>
              </div>
              <br/>

              <p> <b>5.3.</b> Associated Information</p>
                <p> MyBattle11 Platform requires collect and process the following non-personal, non-identifiable Information for effective supply of game play services and ensuring secure, easy, streamlined access to the Users:
              </p>

              
              <div className="ps-md-4 ps-2" > 
                <p>
                 <b>5.3.1.</b> Log Data: For the purposes of error reporting in game play; MyBattle11 Platform employs third party products to help in debugging and error reporting. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics however no Personal Identifiable Information nor Personal Sensitive Information is used for this process.
                </p>
                <p>
                 <b>5.3.2.</b> Cookies: Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers; these do not include any Personal Identifiable Information or Sensitive Personal Information. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                </p>
              </div>


              <br/>

              <p> <strong>6. Use of Information</strong> </p>
              <p> The Information collected shall be used in the following manner by MyBattle11 Platform:</p>

              <p> The Personal Information collected is meant only for the purpose of:</p>
              <p> <strong> 6.1.1. </strong> Enabling account creation of user;</p>
              <p> <strong> 6.1.2. </strong> Improving and enhancing access of the users to MyBattle11 Platform game play features on website and mobile applications including identifying technical impediments;</p>
              <p> <strong> 6.1.3. </strong> Understanding user play requirements and behaviour for betterment of future game play such as features, offers, rewards, contests amongst other terms as are noted in the Terms & Conditions;</p>
              <p> <strong> 6.1.4. </strong> Relevant marketing of prevailing game offers, products, competitions, prizes etc to the user for easy access;</p>
              <p> <strong> 6.1.5. </strong> Processing financial transactions for disbursement and encashment of funds in line with Terms & Conditions of MyBattle11 and its payment settlement partner(s) as per applicable laws.</p>
              <p> <strong> 6.1.6. </strong> Securing the user and MyBattle11 Platform regarding statutory, regulatory & legal compliances including but not limited to prevent fraudulent activity and cooperating with law enforcement agencies.</p>
              <p> <strong> 6.2. </strong> Use of Sensitive Personal Information</p>
              <p> The Sensitive Personal Information collected is meant only for the purpose of:</p>
              <p> <strong> 6.2.1. </strong> Enabling, initiating, processing and executing inter-se financial transactions between MyBattle11 Platform and its Users occurring in relation to the game play features, offers, prizes, rewards opted for or credited to the User by the Company.</p>
              <p> <strong> 6.2.2. </strong> Facilitating and processing, settlement of inter-se financial transactions between Users on MyBattle11 Platform occurring in relation to the game play features, offers, prizes, rewards opted for or credited to the User by the Company.</p>
              <p> <strong> 6.2.3. </strong> Securing the User and MyBattle11 Platform regarding statutory, regulatory & legal compliances including but not limited to prevent fraudulent activity and cooperating with law enforcement agencies.</p>
              <p> <strong> 6.3. </strong> Use of Associated Information</p>
              <p> The Associated Information collected is meant only for the purpose of functionality and improvement of MyBattle11 Platform; testing of features/offers/play options; enhancing User play satisfaction and Platform experience; evaluate usage trends and statistics and all actions ancillary to or in aid of the achieving these. The effective usage, storage and sharing of Associated Information is done with help of support partners/affiliates; the role of which is defined in this Policy.</p>

            
            <br/>

              <p> <strong>7. Storage of PI & SPI</strong> </p>
              <p> <strong> 7.1. </strong> MyBattle11 Platform ensures that there are appropriate contractual controls over any third party that helps the Company process the Personal Information and Sensitive Personal Information collected by MyBattle11 Platform. MyBattle11 Platform ensures that User rights are upheld and their information is secure.</p>
              <p> <strong> 7.2. </strong> MyBattle11 Platform Stores User Information for as long as the User has a profile functional on the Platform. Once they are no longer a User; MyBattle11 Platform will hold the Personal Information and Sensitive Personal Information on its systems for as long as is necessary to comply with legal obligations in the relevant jurisdictions imposed by prevailing laws.</p>

              <br/>


              
              
              <p> <strong> 8. Sharing of PI & SPI</strong> </p>
              <p>  <b> 8.1. </b> MyBattle11 Platform will not sell, trade or share your Information to any third party (except subsidiaries/affiliates/support partners for related offerings) unless, expressly authorized by the User either in writing or electronically for the same.</p>
              <p><b>  8.2. </b> In furtherance of Usage of PI & SPI as outlined in this Policy; the relevant Information may from time to time be shared with Support Partners including but not limited to technical; software; administrative; financial; banking and payments; and legal support by MyBattle11 Platform for the purpose of betterment provision of game play services.</p>


              <p> <strong>9. Support Partners & Affiliates</strong></p>
              <p> <b> 9.1.  </b> MyBattle11 Platform may employ third-party companies and individuals specifically contracted with appropriate confidentiality and security measures.to: </p>
              <ul>
             <li>  	Facilitate MyBattle11 Platform usage; </li>
             <li>  	Provide, assist, facilitate in provision of Service on behalf of the MyBattle11 Platform such as customer care, technical, software, administrative, banking and payment services and legal. </li>
             <li> 	Perform Service-related services; or </li>
              <li> 	Assist MyBattle11 in analysing how its Service is used. </li>
              </ul>
              <p> <b>9.2.</b> MyBattle11 Platform provides these chosen Support Partners access to your relevant User Information as and when need for it arise. </p>
              <p> <b>9.3.</b> The Usage of Information is confined to the usage scope defined in this Privacy Policy. </p>
              <p> <b>9.4.</b> The usage of Information by the support partners is in accordance with applicable laws and enveloped under confidentiality agreements to ensure data protection and privacy of the Users. </p>

              <br/>
              <p> <b>10. Security </b></p>
              <p> MyBattle11 Platform values the trust reposed in it by sharing User Information. It strives to provide commercially acceptable means of protecting User Information both during transmission and, once we receive it, for storage and disposal. All information received is securely stored within databases controlled by us. The databases are stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. MyBattle11 Platform has internal checks and balances to protect Information while processing and maintaining User Information. However, no method of transmission over the internet, or method of electronic storage is 100% secure/breach-proof and therefore cannot be considered to provide or guarantee absolute security. Therefore, while we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. </p>
              <br/>

              <p> <b>11. Third Party Websites </b></p>
              <p> MyBattle11 Platform may contain links to other sites or services. If a User clicks on a third-party link, they will be directed to that site or service. Note that these external sites are not operated by MyBattle11 Platform. Therefore, it is strongly advised that you review the Privacy Policy of these websites. MyBattle11 Platform has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party sites or services. </p>

              <br/>
              <p> <b>12. Age of Consent </b></p>
              <p>  MyBattle11 Platform do not address anyone under the age of 18 and does not knowingly collect personally identifiable information from children under 18. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions. </p>
              <br/>
              <p> <b>13. Deletion of User Information</b> </p>
              <p>  <b>13.1</b> A User is at liberty to delete their Account with MyBattle11 Platform at any point through the website or mobile applications. After deletion, the data of such users will not be shared with any new specialised support partners of MyBattle11. </p>
              <p> <b>13.2</b> The Personal Information of the User will however be retained by MyBattle11 Platform for its original intended use as long as it is relevant and then disposed of as per record retention requirements of applicable laws including longer retention period in case of any law enforcement requirements. </p>
              <p>   <b>13.3</b> The user may unsubscribe from marketing communications by clicking on the “unsubscribe” link located on the bottom of our e-mails, updating your communication preferences, or by sending us email to <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a>. </p>
              
              <br/>
              <p>  <b>14. Confidentiality</b> </p>
              <p> Except as otherwise provided in this Privacy Policy, MyBattle11 Platform will keep the User Information private and will not share it with third parties; unless permitted or mandated by law to do so. </p>

              <br/>
              <p> <b>15. Amendment of Privacy Policy </b> </p>
              <p> As the laws and MyBattle11 Platform evolves, the privacy policy will need to evolve as well to cover new situations. Users are advised to review this Policy regularly for any changes, as continued use is deemed approval of all changes. </p>
              <br/>
              <p> <b>16. Contact Us</b> </p>
              <p> If a User has any questions, suggestions or complaints about the Privacy Policy; please connect with <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a>. They shall undertake all reasonable efforts to address your grievances at the earliest possible opportunity. </p>





              
              





 
               
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      {/* <Footer /> */}
      {/* <!-- End Footer --> */}

      {/* <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a> */}
      {/* <div id="preloader"></div> */}
    </>
  );
};
