import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HomeService from "../../api/services/HomeService";
import LoaderHelper from "../../components/Loading/LoaderHelper";

 const Payment = () => {

  useEffect(()=>{
    getPaymentStatus()
  },[])

  const getPaymentStatus = async () => {
    LoaderHelper.loaderStatus(true)
    const result = await HomeService.getPaymentStatus()
    console.log(result, "resultt")
      // if (result?.success) {
      //   LoaderHelper.loaderStatus(false)
      //   try {
      //     setT20(false)
      //     setT10(false)
      //     setOdi(false)
      //     settestList(result?.data[0])
      //   } catch {
      //     alertErrorMessage(result.message);
      //   }
      // } else {
      //   LoaderHelper.loaderStatus(false)
      //   alertErrorMessage(result.message);
      // }
    };

  return (
    <>

      <section className="payment_page" >
        <div className="container" >
          {/* payment success */}
          <div className="pay_card" >
            <div className="p_top" >
              <i class="ri-checkbox-circle-fill ri-xl text-success"></i>
              <h3>
                Payment Success
              </h3>
              <p>Secure transaction completed, ensuring satisfaction and peace of mind in financial transactions.</p>
            </div>
            <div className="p_list" >
                <p> TXN ID. <span>  #170803172443113</span> </p>
                <p> Amount <span>  Rs. 1541</span> </p>
                <p className="mb-0" > Status <span className="text-success" > <b>  Success</b> </span> </p>
            </div>
            <button className="btn btn-dark w-100 " >  Done </button>

          </div>



          {/* payment pending */}

          <div className="pay_card" >
            <div className="p_top" >
              <i class="ri-error-warning-fill ri-xl text-warning"></i>
              <h3>
                Payment Pending
              </h3>
              <p>Secure transaction completed, ensuring satisfaction and peace of mind in financial transactions.</p>
            </div>
            <div className="p_list" >
                <p> TXN ID. <span>  #170803172443113</span> </p>
                <p> Amount <span>  Rs. 1541</span> </p>
                <p className="mb-0" > Status <span className="text-warning" > <b>  Pending</b> </span> </p>
            </div>
            <button className="btn btn-dark w-100 " >  Done </button>

          </div>



          {/* payment failed */}
          <div className="pay_card" >
            <div className="p_top" >
              <i class="ri-close-circle-fill ri-xl text-danger"></i>
              <h3>
                Payment Failed!
              </h3>
              <p>Secure transaction completed, ensuring satisfaction and peace of mind in financial transactions.</p>
            </div>
            <div className="p_list" >
                <p> TXN ID. <span>  #170803172443113</span> </p>
                <p> Amount <span>  Rs. 1541</span> </p>
                <p className="mb-0" > Status <span className="text-danger" > <b>  Failed</b> </span> </p>
            </div>
            <button className="btn btn-dark w-100 " >  Done </button>

          </div>

        </div>
      </section>
        
    </>
  );
};

export default Payment
