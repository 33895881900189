import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const TermsNConditions = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.mybattle11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Terms and Conditions</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Terms and Conditions</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                <div class="TermsCondtnsBox">
                  {/* <h5>Last Updated: 22 June, 2023</h5> */}
                  <p>
                  These Terms & Conditions (“Terms”) pertain to (i) use of our website www.mybattle11.com (“Website”), our applications (“Application”) or any products or services in connection with the Application/, Website/products (“Services”)  or (ii) any modes of registrations or usage of products including through SD cards, tablets or other storage/transmitting device (Website, Application, Services collectively known as the ‘MyBattle11 Platform’ hereinafter) are between SPODS Technologies Private Limited (“Company/We/Us/Our”) and its users (“User/You/Your”).
                  </p>
                  <ol class="termsContent ftFx13 terms_condition_list">
                    <li>
                      <h3 id="MyBattle11"> Contract</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p>  a.	These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.</p>
                          <p>b.	Please read the Terms and the privacy policy of the Company (“Privacy Policy”) available at https://mybattle11.com/. The Privacy Policy shall be deemed to be part and parcel of the present Terms and vice versa. The provisions of the Terms & Conditions shall apply mutatis mutandis to the present Privacy Policy. In the event of any discrepancy between the Terms and any other policies with respect to the Application or Website or Services or products, the provisions of the Terms shall prevail.</p>
                          <p>c.	MyBattle11 Platform has formulated ‘Guidelines’ for game play features and rules through quick access tabs/pages across the Platform; these are linked in these Terms and are deemed to be part and parcel of the present Terms and vice-versa. The provisions of the Terms & Conditions shall apply mutatis mutandis to the Guidelines. In the event of any discrepancy between the Terms and any other policies with respect to the Application or Website or Services or products, the provisions of the Terms shall prevail.</p>
 
                        </div>
                      </div>
                    </li>



                    <li>
                      <h3 id="MyBattle11">     Acceptance of Contract</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p>  a.	Your use/access/browsing of MyBattle11 Platform (with or without payment/with or without registration/subscription) through any means shall signify your acceptance of the Terms and your agreement to be legally bound by the same. </p>
                          <p> b.	If you do not agree with the Terms or the Privacy Policy, please do not use MyBattle11 Platform. Any access to our Services/Application/products through registrations/subscription is non-transferable.</p> 
                        </div>
                      </div>
                    </li>


                    

                    <li>
                      <h3 id="MyBattle11">     Eligible Users & Self Declarations</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">  
                          
                          <p>  a.	Persons who are "competent/capable" of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for MyBattle11 Platform. Persons who are minors, un-discharged insolvents etc. are not eligible to use MyBattle11 Platforms.</p>
                          <p> b.	At present MyBattle11 Platform is available only those currently residing in India. Persons located in the States of Assam, Odisha, Nagaland, Sikkim, Andhra Pradesh, and Telangana (“Concerned States”) are not eligible to use MyBattle11 platform as the same is restrained or prohibited by the locals applicable in the said States.</p>
                          <p> c.	User discretion is advised; any wrongful declaration; liability thereto or penalty, for violation of the applicable laws is solely of the user.</p>
                          <p> d.	A User shall not register or operate more than one User profile on MyBattle11 Platform based on the information provided.</p>
                          <p> e.	You acknowledge that the Company does not have the responsibility to ensure that You conform to the aforesaid eligibility criteria. It shall be Your sole responsibility to ensure that You meet the required qualification.</p>
                          <p> f.	You shall be solely responsible to ensure you upload true and valid User login/register using your own updated credentials. You warrant that all the data provided by You is accurate and complete and acknowledge that if any Information to be fraudulent, impersonating another person or User or fake; the Company will enforce its right to terminate your profile account for such default.</p>
                          <p> g.	MyBattle11 Platform shall bear no responsibility or liability for any false information provided to it by Users.</p>
                          <p> h.	MyBattle11 Platform will not differentiate between who is using the device to access the Application, Website or Services or products, so long as the log in/access credentials match with the User’s self-declared inputs.</p>
 
                        </div>
                      </div>
                    </li>

 

                    <li>
                      <h3 id="MyBattle11">     Game of Skill & Responsible Gaming</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          
                          <p> a.	MyBattle11 Platform strives to ensure responsible gaming in its game of skills offerings across the Platform. However, it recognises that such games may be addictive and </p>
                          <p> b.	The Public Gambling Act, 1867 (“PGA”) provides an exception for games which involve element of skill and such games are considered to be legal. Fantasy Sports play have been globally recognised as ‘Games of Skill’ wherein the players have to make their own calculated strategies to pick the players for their teams based on several factors including evaluation of historical data of the players, the viability of the teams, the current conditions of the players etc. </p>
                          <p> c.	There have been various judicial pronouncements with respect to the legality of fantasy sports leagues in India as the same are considered ‘Game of Skill’ and are currently permissible in India (in most States).  </p>
                           
                          <p>
                          For instance:   
                          </p>
                          <ul>
                          <li> (i) Rummy was recognised as a game of skill [AIR 1968 SC 825]  </li>
                          <li> (ii) Fantasy Cricket was recognised as a game of skill by the Punjab and Haryana High Court in 2017 and the Rajasthan High Court in 2020 and affirmed by the Supreme Court in Avinash Mehrotra vs. State of Rajasthan & Ors. on 30.07.21. </li>
                          <li>  (iii) State of Kerela has permitted online Rummy as the same is considered a game of skill. </li>
                          </ul>
                          <p> d.	As on date, the Specific Laws in Jurisdictions of States of Assam, Odisha, Nagaland, Sikkim, Andhra Pradesh and Telangana do not permit ‘Games of Skill’ in their territorial jurisdictions including fantasy sports. </p>
                          <p> e.	The evolving legal position of the respective Concerned State regarding Games of Skill is under consideration by the Courts of India and the eligibility of users from the Concerned States is subject to final outcome of these legal challenges. </p>

 
                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="MyBattle11">       Registration    </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                        <p> a.	In order to access the Services and to avail the use of the Application/products, you shall be required to register yourself with the Application/Services/products and maintain an account with the Application/Services/products.</p>
                        <p> b.	You will be required to furnish certain information and details, including Your name, Date of Birth, mobile number, e-mail address, residential address, payment information (credit/debit card details) if required, and any other information deemed necessary by the Application. With respect to the provision of information, the following may be noted: -</p>
                        <p> c.	It is Your sole responsibility to ensure that the account information provided by You is accurate, complete and latest.</p>
                        <p> d.	Each User based on information provided will be allotted one User Account/Profile.</p>
                        <p> e.	Upon registration through any means whatsoever, the Company may contact You through the registered mobile number or e-mail or any other mobile number or contact number or email provided by You to enable effective provision of Services.</p>

                        </div>
                      </div>
                    </li>

                    <li>
                      <h3 id="MyBattle11">     User Responsibilities</h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                        <p> a.	You shall be responsible for maintaining the confidentiality of the account information and for all activities that occur under Your account. You agree to (i) ensure that You successfully log out from Your account at the end of each session; and (ii) immediately notify the Company of any unauthorized use of Your account. </p>
                        <p> b.	If there is reason to believe that there is likely to be a breach of security or misuse of Your account, we may request You to change the password or we may suspend Your account without any liability to the Company, for such period as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from Your failure to comply with this provision. </p>
                        <p> c.	In case a User has any winnings on MyBattle11 Platform, they are advised to submit their PAN Number at the time of withdrawal for tax compliances. You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot always guarantee accessibility to the Application. </p>
                        <p> d.	In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time. </p>
                        <p> e.	Your use of MyBattle11 Platform is solely for Your personal and non-commercial use. </p>
                        <p> f.	You are prohibited from using any unfair means to manipulate/influence MyBattle11 Platform that may constitute a violation of any Guidelines and these Terms. </p>
                        <p> g.	Any use of the Application, Website, Services or products or their contents other than for personal purposes is prohibited. </p>
                        <p> h.	Users consent to receiving communications such as announcements, administrative messages, and advertisements from MyBattle11 Platform or any of its partners, licensors or associates. </p>
                        <p> i.	No User shall be permitted to perform any of the following prohibited activities while availing using MyBattle11 Platform: </p>
                        <p> j.	Interfering with any other person's use or enjoyment of MyBattle11 Platform; </p>
                        <p> k.	Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity; </p>
                        <p> l.	Access or use of MyBattle11 Platform in any manner that could damage, disable, overburden or impair any of the Application's/Website's servers or the networks connected to any of the servers on which the Application/Website is hosted; </p>
                        <p> m.	Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Application/Website/Services/products, violate any requirements, procedures, policies or regulations of networks connected to the Application/Website/Services/products, or engage in any activity prohibited by these Terms; </p>
                        <p> n.	Disrupt or interfere with the security of, or otherwise cause harm to, MyBattle11 Platform, materials, systems resources, or gain unauthorized access to user accounts, passwords, servers or networks connected to or accessible through the Application/Website/Services/products or any affiliated or linked sites; </p>
                        <p> o.	Interfere with, or inhibit any user from using and enjoying access to the Application/Website/ Services/products, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Application/Website/Services/products; </p>
                        <p> p.	Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to manipulate traffic to Mybattle11 Platform, to access, acquire, copy or monitor any portion of the Application /Website/Services/products, or in any way reproduce or circumvent the navigational structure or presentation of MyBattle11 Platform, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website/Services/products; </p>
                        <p> q.	Use the Services for purposes that are not permitted by a) these Terms; b) and any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or </p>
                        <p> r.	Violate any of the terms specified under the Terms for the use of the Application /Website/Services/products. </p>

                        </div>
                      </div>
                    </li>


                    <li>
                      <h3 id="MyBattle11">     </h3>
                      <div class="t_c_content">
                        <div class="pdLt20">
                          <p>   </p>
                          <p> </p> 
                        </div>
                      </div>
                    </li>


                      <li>
                        <h3 id="MyBattle11">  Contests & Participation   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20"> 

                          <p> a.	MyBattle11 Platform has paid Contests in which the Users may participate by paying a pre-designated amount as provided on the relevant Contest page. This ‘pre-designated amount’ shall be inclusive of the following: -</p>
                          
                          <p>
                          <b>
                          8.1.1. Pre-determined Platform Fee towards providing the services of operating the Fantasy Sports platform;
                          </b>
                          </p>
                          <p>  b.	The User’s pre-determined contribution towards the prize pool. </p>
                          <p>  c.	The Users with the highest aggregate points at the end of the contest shall be eligible to win a pre-designated prize which is disbursed out of prize pool, as stated on the relevant Contest(s) page. </p>
                          <p>  d.	At present, MyBattle11 Platform offers the following contests formats for its Users (1) Open Contest where Users can participate in a Contest with other Users without any restriction on participation and (2) Invite-Only contests, where Users can invite specific Users into a Contest and restrict participation to such invited Users. A user can enter a maximum of 500 contest (including both Open contests and Invite-Only contests) per match. Any participation in a contest more than 500 shall be automatically prohibited. All rules applicable to Contest(s) as set out herein shall be applicable to both formats of the Contest(s). Users by participating in a Contest(s) hereby authorize MyBattle11 Platform to appoint a third party/ Trustee/Escrow Agent to manage user’s funds on user’s behalf. </p>
                          <p>  e.	Unless otherwise specified in relation to any Contest ("“Multiple Entry Contest"”), Participants acknowledge and agree that they may enter only one Team in any Contest offered in relation to a Sport Event. In case of Multiple Entry Contest(s), a Participant may enter more than one Team in a single Multiple Entry Contest </p>
                          <p>   f.	Only those Participants who have successfully registered on MyBattle11 Platform as well as registered prior to each contest in accordance with these Terms shall be eligible to participate in the Contest and win predesignated prizes. </p>
                          <p>  g.	While configuring each team, a User can make as many changes as they like. But only until the start of the match. ‘Start of the match’ for purposes of MyBattle11 Platform is not synchronised with the conventional real-world sport starting. Depending upon the circumstances of each match, the participants can edit their teams till the official match start time as declared by the officials of the Sport Event or adjusted deadline. MyBattle11 Platform reserves the right to abandon a specific round or adjust the deadline of a round in certain specific, uncertain scenarios, which are beyond its reasonable control, including but not limited to the following: </p>
                          <p>  h.	Actual matches start time is before the official deadline: </p>
                          <p>  i.	In the case of Cricket, MyBattle11 Platform reserves the right to adjust the deadline by a Maximum of 10 minutes or 3 overs bowled, whichever is less, before the official match start time. Where official match time cannot be verified by MyBattle11 Platform through reliable and/or publicly available sources, it reserves the right to adjust the deadline to such a time by which a maximum of 3 overs in the given match are bowled. </p>
                          <p>  j.	In case of other sports, MyBattle11 Platform reserves the right to adjust the deadline to a maximum of 10 minutes post actual match start time. In case the actual match start time is more than 10 minutes of official deadline, the contest will be abandoned. </p>
                          <p>  k.	Actual match start time is after the official deadline: MyBattle11 Platform reserves the right to extend the deadline or abandon the contest/game based on the circumstances such as delayed toss, interruption on account of weather, non-appearance of teams, technical/equipment glitches causing delays etc. </p>
                          <p>  l..	MyBattle11 Platform for ease and reference of its users lists available players and provides rankings for the same as per its own algorithms; these are no way dependent, related or interlinked with any rankings released by sporting federations such as ICC. </p>
 
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Winners & Prize Distribution   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                            <p>  a.	To win a contest, the user has to earn the highest points by using his knowledge & skills to select 11 players among both teams and select 1 captain and 1 vice-captain. Players are given points on the basis of their performance during the match. Fantasy point System provided on www.mybattle11.com. </p>
                            <p> b.	In the event of a tie, all the Participants shall be declared Winners and the prize shall be equally divided among such Participants. </p>
                            <p> c.	MyBattle11 Platform shall not be liable to pay any prize if it is discovered that the Users have not abided by these Terms and Conditions.</p>
                            <p> d.	Subject to payment terms, Winnings will only be disbursed to the User after they have complied with the necessary disclosures including verification of bank account.</p>
                            <p> e.	Winners shall be contacted through the e-mail address provided at the time of registration. The verification process and the documents required for the collection of prize shall be detailed to the Winners at this stage.</p>
                            <p> f.	The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes.</p>
                            <p> g.	All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.</p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Scope Of Usage   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	The Company reserves the right at its sole discretion to remove, review, edit or delete any content. Similarly, we will not be responsible or liable for any content uploaded by Users directly. </p>
                          <p> b.	In the preparation of the Application/Website/Services/products and contents therein, every effort has been made to offer the most current, correct, and clearly expressed information possible. Nevertheless, inadvertent errors may occur. In particular, but without limiting anything here, the Company disclaims any responsibility for any errors and accuracy of the information that may be contained in the Application.</p>
                          <p> c.	We reserve the right to;</p>
                            <ul>
                            <li>   I) 	extend, cancel, discontinue, prematurely withdraw or modify any of Our Services at Our discretion, Restrict, suspend, or terminate any User’s access to all or any part of MyBattle11 Platform and Services as per its discretion. </li>
                            <li>  II)	Deactivate or delete a user’s account and all related information and files on the account. </li>
                            <li>  III)	Take restriction. Prohibition, deactivation measures against Users found to be using Insider information of any real-time players, matches across any sports that may impact the fair playing conditions of MyBattle11 Platform. </li>
                            <li>   IV)	Modify any of Our Services at Our discretion. </li>
                            <li>  V)	Monitor the download and usage of the Application/Services/products and the contents thereof by the User, to analyse such usage to enable effective and efficient usage of the Services. </li>
                            <li>  VI)	Establish general practices and limits concerning use of MyBattle11 Platform </li>
                            <li>  VII)	Revise or make additions to the roster of players available for selection in a match Pools on account of revisions to the roster of players involved in the relevant Sports Event. </li>
                            <li>  VIII)	Assign its rights and liabilities to all User accounts hereunder to any entity (post intimation of such assignment shall be sent to all Users to their registered email ids). </li>

                            </ul>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Payment terms   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">

                         <p>
                         a.	In respect of any transactions entered on MyBattle11 Platform, including making a payment to participate in the paid versions of Pools(s), Users agree to be bound by the following payment terms:
                         </p>

                            <ul>
                                  <li>  I)	The payment of pre-designated amount by the user for participation in any contest includes the pre-determined platform fee payable to MyBattle11 as well as the pre-determined contribution of the User towards the prize pool. </li>
                                  <li>    II)	MyBattle11 Platform portal hosts a number of Pools for which it reserves the right to charge a pre-determined Platform Fee, which would be specified and notified by MyBattle11 Platform on the Contest page prior to a User's joining of such Contest along with the pre-determined User contribution towards prize pool. </li>
                                  <li>     III)	The Platform Fee and applicable tax thereon will be debited from the User’s account balance along with the entry-fee for the Pool, and MyBattle11 Platform shall issue an invoice for such debit to the User. </li>
                                  <li>    IV)	The User may participate in a Pool wherein the User has to contribute a pre-specified contribution towards the Prize Pool of such Pool, which will be passed on to the Winner(s) of the Pool after the completion of the Pool as per the terms and conditions of such Pool. It is clarified that MyBattle11 Platform has no right or interest in this Prize Pool, and only acts as an intermediary engaged in collecting and distributing the Prize Pool in accordance with the Pool terms and conditions. The amount to be paid-in by the User towards the Prize Pool would also be debited from the User’s account balance with MyBattle11 Platform. </li>
                                  <li>     V)	The Winners shall bear all transaction charges levied for delivery of cash prizes. </li>
                            </ul>         
                                    
                            <p>
                            b.	28% GST is applicable on amount deposited by the User in its Unutilised Account with MyBattle11 platform. User can view the GST paid on each deposit.
                            </p>        
                             <p>
                             c.	All Users are provided with few categories of accounts for the processing and reconciliation of payments: (i) Unutilised, (ii) Winnings, (iii) Bonus, (iv) Withdrawable. It is clarified that in no instance the transfer of any amounts in the User's accounts to any other category of account held by the user or any third-party account, including a bank account held by a third party:
                            </p>         
                              <ul>     
                                <li>     I)	User’s remitting amounts through the designated payment gateway shall be credited to Unutilised Account. </li>
                                <li>     II)	User's winnings in any Contest on MyBattle11 Platform will reflect as credits to the Winnings Account. </li>
                                <li>    III)	Each time a User participates in any MyBattle11 contest, the pre-designated amount shall be debited in the Unutilised account. In disbursing cum deducting amounts from the User’s accounts towards the pre-designated amount of such user shall be debited from the Unutilised Account and thereafter, any remaining amount of participation fee shall be debited from the Winnings Account. </li>
                                <li>     IV)	In case there is any amount remaining to be paid by the User in relation to such User’s participation in any match(s) or Contest(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of the pre-designated amount, the amount in excess shall be transferred to the User’s Unutilised Account and will be available for use in participation in any match(s) or Contest(s). </li>
                                <li>    V)	All Users shall be required to remit the required amount to MyBattle11 Platform through the designated payment gateway. The payment made shall be credited to the User’s Unutilised accounts and each time a User enters a Contest, the applicable amount towards shall be debited from the User’s Unutilised account. In debiting amounts from the User’s accounts towards the participation fee of such user in any round or Pools(s), MyBattle11 Platform shall first debit the User’s Unutilised Account. In case there is any amount remaining to be paid by the User in relation to such User’s participation in any round(s) or Pools(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of participation fee, the amount in excess shall be transferred to the User’s Unutilised account and will be available for use in participation in any round(s) or Pools(s) or for withdrawal in accordance with these Terms and Conditions. Debits from the Account to enable a user’s participation in a Pool shall be made in order of the date of credit of amounts in the Account, and accordingly amounts credited into Account earlier in time shall be debited first. </li>
                              </ul>       
                         

                          <p> d.	All amounts credited into a User's Unutilised & Winnings Account must be utilised within 355 days of credit. In case any unutilised amount lies in the Account after the completion of 355 days from the date of credit of such amount, MyBattle11 Platform reserves the right to forfeit such unutilised amount, without liability or obligation to pay any compensation to the User. </p>
                          <p> e.	Withdrawal of any amount standing to the User's credit in the Winnings Account may be made by way of a request to MyBattle11 which shall affect an online transfer to the User's bank account on record within a commercially reasonable period of time. Such transfer will reflect as a debit to the User's Winnings Account. MyBattle11 may not charge any processing fee for the online transfer of such amount from the Winnings Account to the User's bank account on record with MyBattle11. </p>
                          <p> f.	Users are required to note that they will be required to provide valid photo identification and address proof documents for proof of identity in order for MyBattle11 to process the withdrawal request. The name mentioned on the User's photo identification document should correspond with the name provided by the User at the time of registration, as well as the name and address existing in the records of the User's bank account as provided. In the event a User has one or more of the following lapses(i) that no bank account has been registered by the User against such User's account (ii) the User has not verified his/her User account to the satisfaction of MyBattle11 and in accordance with these Terms and Conditions (iii) the User fails to register a bank account with his/her User Account (iv) verify his/her User Account; MyBattle11 shall not be liable to transfer any winnings to the User due to lack of tax compliance by the User. </p>
                          <p> g.	MyBattle11 Platform may charge any processing fee for the online transfer of such amount from the Account to the User’s bank account on record with MyBattle11 Platform. Users are requested to note that they will be required to provide valid photo identification and address proof documents for proof of identity and address in order for MyBattle11 Platform to process the withdrawal request. The name mentioned on the User’s photo identification document should correspond with the name provided by the User at the time of registration on MyBattle11 Platform, as well as the name and address existing in the records of the User’s bank account as provided to MyBattle11 Platform. If no bank account has been registered by the User against such User’s account with MyBattle11 Platform, or the User has not verified his/her User account with MyBattle11 Platform or, to MyBattle11 Platform’s satisfaction and in accordance with these Terms and Conditions, MyBattle11 Platform shall provide such User with a notification to the User’s email address as on record with MyBattle11 Platform at least 30 days prior to the Auto Transfer Date, and in case the User fails to register a bank account with his/her User Account and/or to verify his/her User Account by the Auto Transfer Date, MyBattle11 Platform shall be entitled to forfeit any amounts subject to transfer on the Auto Transfer Date. Failure to provide MyBattle11 Platform with a valid bank account or valid identification documents (to MyBattle11 Platform’s satisfaction) may result in the forfeiture of any amounts subject to transfer in accordance with this clause. </p>
                          <p> h.	Users agree that once they confirm a transaction on MyBattle11 Platform, they shall be bound by and make payment for that transaction. </p>
                          <p> i.	The User acknowledges that transactions on MyBattle11 Platform may take up to 24 hours (or maybe up to 7 days) to be processed. Users agree not to raise any complaint or claim against MyBattle11 Platform in respect of any delay, including any lost opportunity to join any Pools or round due to delay in crediting of transaction amount into any of the User’s accounts. </p>
                          <p> j.	In any case of failure of the withdrawal amount transaction done using instant bank transfer mode from MyBattle11 Platform, due to users' bank or the source bank, in that case, the user's amount will be added back to users MyBattle11 Platform wallet within 24 to 48 hrs. </p>
 
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Bonus   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	In order to conduct promotional activities, MyBattle11 may gratuitously issue Playing Bonus/Royalty Rewards/Promotional Scheme etc. to the User for the purpose of participation in any Contest(s) and no User shall be permitted to transfer or request the transfer of any Bonus in to the Unutilised or Winnings Account </p>
                          <p> b.	The usage of any Bonus issued shall be subject to limitations and restrictions, including but not limited to, restrictions as to time within which such Bonus must be used. Any Bonus shall be consumed within 30 days from the day it has been received in the user wallet. Any Bonus shall expire and be without effect at the end of 30 days from the date of credit of that Bonus.</p>
                          <p> c.	The issue of any Bonus to the user is subject to the sole discretion of MyBattle11 and cannot be demanded by any User as a matter of right.</p>
                          <p> d.	The Bonus granted to the user may be used by such User for the purpose of setting off against the contribution to prize pool in any Contest, in accordance with these Terms and Conditions. The Bonus shall not be withdraw-able or transferrable to any other account of the User, including the bank account of such User, or of any other User or person, other that as part of the winnings of a User in any Contest(s). In case the User terminates his/her account or such account if terminated by MyBattle11, all Bonus granted to the user shall return to MyBattle11 and the User shall not have any right or interest on such points.</p>



                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Taxes Payable   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  a.	Effective 1st April 2023, 30% tax as TDS will be deducted on net winnings at the time of withdrawal </p>
                          <p> b.	In case of no withdrawal in the entire financial year, TDS will be deducted from net winnings as on 31st March of the current Financial Year.</p>
                          <p> c.	Net winnings are defined as the difference between your gains and spends.</p>
                          <p> d.	Net winnings = Total winnings - (Total Entry Amount-Bonus/Discount)</p>
                          <p> e.	*Subject to change as per the rules that are to be notified under the Income Tax 1961</p>
                          <p> f. 	Winners will be provided TDS certificates electronically on the registered email associated with the User Account; in respect of such tax deductions unless otherwise specified by the user through written request through registered email to provide hard copy of TDS certificate.</p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Refund & Cancellation Policy   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	All transactions made by User on MyBattle11 Platform including deposits and withdrawals are final and no cancellations will be permissible. </p>
                          <p> b.	MyBattle11 Platform reserves the right to cancel any pool(s)/ match(es) for any reason, with or without disclosure of the reason, and refund all transactions paid for such pools/matches by any and all users within 3 business days by the original mode of payment used by the User for such transaction. </p>
                          <p> c.	MyBattle11 Platform strives for customer satisfaction and in furtherance of the Terms & Conditions Policy the following exceptional circumstances allow for refunds: </p>
                          <p> i)	Only force majeure or </p>
                          <p> ii)	Payment Gateway failure </p>
                          <p>  d.	For any Payment Gateway failure; the User must submit a request for review within 3 days of such transaction along with relevant proof to <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a>. The request shall be reviewed, and resolution (if applicable) will be provided to the User within 2 weeks of raising the request. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">    Refer and Earn Policy </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  a.	The Refer and Earn Policy of MyBattle11 platform allows its Users to invite their friends to join MyBattle11 platform. On an ‘Existing User’ inviting their friend to join MyBattle11 platform using their unique referral code/link, the Existing User as well as their invited friend shall stand a chance to earn a ‘Referral Bonus’ as provided hereinunder. </p>
                          <p>  b.	In order to obtain the benefit of the Refer and Earn Policy, a User is required to comply with the following conditions: - </p>
                          <p>  i)	A user must be an ‘Existing User’ on MyBattle11 Platform. For the purposes of these Terms, an 'Existing User' shall mean a user who presently operates an account with MyBattle11 Platform or operated an account with the Platform at any point of time. An Existing User shall not be permitted to create a new Account to take the benefit of the Refer and Earn Policy. MyBattle11 will determine in its sole discretion whether an Invitee is an existing user of MyBattle11 or not and take appropriate action. </p>
                          <p>  ii)	The said Existing User must be eligible to participate in the contests hosted on MyBattle11 Platform. </p>
                          <p>  iii)	The User has not made multiple accounts on MyBattle11 Platform. </p>
                          <p>   c.	The Existing User at the time of registering on MyBattle11 Mobile Application will be provided with a unique referral link or code, which can be used by the Inviting User to invite their friends to MyBattle11 Platform. On receiving the link or code from the Inviting User, the Invitee may either click on the link, consequent to which such Invitee will be directed to a registration page and will be provided the option to download MyBattle11 Mobile Application on their mobile device and register their account on the platform; or download and install MyBattle11 Mobile Application on their device independently and register an account on MyBattle11 Platform using the unique referral code shared by the Inviting User. </p>
                          <p>  d.	On the Invitee successfully downloading and registering on MyBattle11 Mobile Application, the Inviting User shall be entitled to earn a ‘Refer Bonus’ and the Invitee User shall be entitled to earn a ‘Refer Bonus’ subject to the Invitee User having verified his account and after joining any paid contest on MyBattle11 Mobile Application. </p>
                          <p>  e.	The ‘Referral Bonus’ shall be credited to Bonus Account of the Users and can be used only to join contests on MyBattle11 Platform. No part of the Referral Bonus may be used to join private contests or be withdrawn or transferred to any other cash balance account held by the Users with MyBattle11 or to any third-party account or to any bank/payment instrument account. The Referral Bonus shall expire and be without effect at the end of 30 days from the date of credit of the Referral Bonus. </p>
                          <p>  f.	MyBattle11 may substitute or change the Bonus Amount offered under this Policy at any time without notice. </p>
                          <p>  g.	This Policy cannot be clubbed with any other contests/promotions/programs that are running simultaneously and organised or conducted by MyBattle11. </p>
                          <p>   h.	MyBattle11 reserves the right to change/modify/or withdraw the Policy and/or change these terms and conditions without any prior notice of the same at its sole discretion. </p>
                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Intellectual Property Rights   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p>  a.	Acknowledgement & Recognition of IPR and other Rights: </p>
                          <p> i)	MyBattle11 acknowledges that the ICC, BCCI, IPL, CPL, national cricket boards, domestic tournament organisers, own all proprietary names and marks relating to the relevant tournament or competition. </p>
                          <p> ii)	MyBattle11 acknowledges that intellectual property rights in the logo, names and marks, may be owned by a third party including the sport federations, clubs, teams, leagues, cups, clubs, players’, managers, broadcasters etc. </p>
                          <p> b.	Except as mentioned below, all information, content, material, trademarks, services marks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video, script and audio, contained on MyBattle11 Platform are proprietary property of the Company (“Proprietary Information”). </p>
                          <p> c.	No Proprietary Information may be copied, downloaded, reproduced, modified, republished, uploaded, posted, transmitted, or distributed in any way without obtaining prior written permission from the Company and nothing on MyBattle11 Platform shall be or deemed to confer a license of or any other right, interest or title to or in any of the intellectual property rights belonging to the Company, to the User. </p>
                          <p> d.	You may own the medium on which the information, content or materials resides, but the Company shall at all times retain full and complete title to the information, content or materials and all intellectual property rights inserted by the Company on such medium. </p>
                          <p> e.	Certain contents on the Website may belong to third parties. Such contents have been reproduced after taking prior consent from said party and all rights relating to such content will remain with such third party. </p>
                          <p> f.	Further, you recognize and acknowledge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third party shall continue to vest with such party and You are not permitted to use the same without the consent of the respective third party. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">    Third Party Sites, Services and Products </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	MyBattle11 Platform contains content that is created by MyBattle11 Platform as well as content provided by third parties. MyBattle11 Platform does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not rely upon by the Users in utilizing MyBattle11 Platform Services provided on MyBattle11 Platform including while participating in any of the Pools hosted on MyBattle11 Platform. </p>
                          <p> b.	MyBattle11 Platform for indicative and information purposes displays the starting line-up of real-world players to assist a User in selecting his/her chosen fantasy team. While indicating a Player's inclusion in starting line-up is given by MyBattle11 Platform on the basis of information/data received through feed providers, publicly available information, Users are advised to do a thorough research of their own from official sources and/or other available sources of information. MyBattle11 Platform shall not take any liability, if a player earlier indicated as 'Playing' does not play or start for any reason whatsoever. </p>
                          <p> c.	MyBattle11 Platform does not exercise control over any Internet sites apart from its own and cannot be held responsible for any content residing in any third-party Internet site. MyBattle11 Platform inclusion of third-party content or links to third-party Internet sites is not an endorsement by MyBattle11 Platform of such third-party Internet site. </p>
                          <p> d.	Users’ correspondence, transactions or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the before to transacting or availing of the services of such third party. User agrees that MyBattle11 Platform will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions with third parties. Any questions, complaints, or claims related to any third party product or service should be directed to the appropriate vendor. </p>
                          <p> e.	The Company nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on Application/Website/Services/products for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Limitation of Liability & User Release   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	Users shall access MyBattle11 Platform Services provided on MyBattle11 Platform voluntarily and at their own risk. MyBattle11 Platform shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during access to MyBattle11 Platform Services (including participation in the Pools(s)) or as a result of acceptance of any prize. </p>
                          <p> b.	By entering MyBattle11 Platform and accessing MyBattle11 Platform Services provided therein, Users hereby release from and agree to indemnify MyBattle11 Platform and/ or any of its directors, employees, partners, associates and licensor's, from and against all liability, cost, loss or expense arising out their access to MyBattle11 Platform Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise. </p>
                          <p> c.	MyBattle11 Platform accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes. </p>
                          <p> d.	Users shall be solely responsible for any consequences which may arise due to their access of MyBattle11 Platform Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to MyBattle11 Platform Services, including provision of incorrect address or other personal details. Users also undertake to indemnify MyBattle11 Platform and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by MyBattle11 Platform on account of such act on the part of the Users. </p>
                          <p> e.	Users shall indemnify, defend, and hold MyBattle11 Platform harmless from any third party/entity/organization claims arising from or related to such User’s engagement with MyBattle11 Platform or participation in any Pools. In no event shall MyBattle11 Platform be liable to any User for acts or omissions arising out of or related to User’s engagement with MyBattle11 Platform or his/her participation in any Pool(s). </p>
                          <p> f.	In consideration of MyBattle11 Platform allowing Users to access MyBattle11 Platform Services hosted on MyBattle11 Platform, to the maximum extent permitted by law, the Users waive and release every right or claim, all actions, causes of actions (present or future) each of them has or may have against MyBattle11 Platform, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of MyBattle11 Platform Services or related to the Pools or the prizes of the Pools. </p>
                          <p> g.	The Company's products and / or Services, including the Application and content, are compatible only with certain devices/tablets/instruments/hardware. The Company shall not be obligated to provide workable products and / or services for any instruments that are not recognized by the Company or those instruments that may be purchased from any third party which are not compatible with the Company's products and Services. The company reserves the right to upgrade the table/ type of compatible devices as required from time to time. </p>
                          <p> h.	The Company shall have no responsibility for any loss or damage caused to tablet or any other hardware or software and/or instrument, including loss of data or effect on the processing speed, resulting from Your use of our products and Services. </p>
                          <p> i.	You agree to defend, indemnify and hold harmless the Company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:  </p>
                          <p> (i) Your use of and access of the Application/Website/Services;  </p>
                          <p> (ii) Your violation of any term of these Terms or any other policy of the Company;  </p>
                          <p> (iii) Your violation of any third party right, including without limitation, any copyright, property, or privacy right; or  </p>
                          <p> (iv) any claim that Your use of the Application/Website/Services has caused damage to a third party. This defence and indemnification obligation will survive these Terms. </p>
                          <p> j.	In no event shall the Company, its officers, directors, employees, partners or agents be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, Your use of, or access to, the Application. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">   Publicity  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	Acceptance of a prize by the Winner constitutes permission for MyBattle11 Platform, and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever. The Winners further undertake that they will be available for promotional purposes as planned and desired by MyBattle11 Platform without any charge. The exact dates remain the sole discretion of MyBattle11 Platform. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions.</p>
                          <p> b.	The Company endeavours to uphold the ethical standards set forth by the I&B Ministry of India with regard to advertising. ,/</p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">   Violation/ Breach of Terms  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	In the event any User breaches, or the Company reasonably believes that such User has breached these Terms, or has illegally or improperly used MyBattle11 Platform may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User’s access to all or any part of MyBattle11 Platform Services, deactivate or delete the User’s account and all related information on the account, delete any content posted by the User on MyBattle11 Platform and further, take technical and legal steps as it deems necessary. </p>
                          <p> b.	If MyBattle11 Platform charges its user a fees in respect to any MyBattle11 Platform services, MyBattle11 Platform shall without any delay repay such fees in the event of suspension or removal or the user account or MyBattle11 Platform services on account of any negligence or deficiency on the part of MyBattle11 Platform but not if such suspension or removal is affected due to: </p>
                          <p> i)	Any breach or inadequate performance by the User of any of these Terms and Conditions. or </p>
                          <p> ii)	Any circumstances beyond the reasonable control of MyBattle11 Platform. </p>
                          <p> (A)	Any violation by You of the terms of this Clause may result in immediate suspension or termination of Your Accounts apart from any legal remedy that the Company can avail. In such instances, the Company may also disclose Your Account Information if required by any Governmental or legal authority. You understand that the violation of these Terms could also result in civil or criminal liability under applicable laws. </p>
                          <p> (B)	The Company has the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the Application or the Services as it deems fit at any time without notice. Further, the Company has the right to amend these Terms from time to time without prior notice to you. The Company makes no commitment, express or implied, to maintain or continue any aspect of the Application. You agree that the Company shall not be liable to You or any third party for any modification, suspension or discontinuance of the Application/Services. All prices are subject to change without notice. </p>
                          <p> (C)	In the event of Your breach of these Terms, you agree that the Company will be irreparably harmed and may not have an adequate remedy in money or damages. The company, therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction. The Company's right to obtain such relief shall not limit its right to obtain other remedies. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Dispute Resolution & Applicable Law   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	The Terms for MyBattle11 Platform shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. </p>
                          <p> b.	Dispute arising out of, or in connection with MyBattle11 Platform; the construction, validity, interpretation and enforceability of these Terms/Privacy Policy/Guidelines, the rights and obligations of the User(s) shall first be sought to be resolved through mutual discussions. The party raising the dispute shall provide a written notification (“Notification”) to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through mutual discussions. In the event, that the parties are unable to resolve the dispute within thirty (30) days of receipt of Notification, the dispute shall be settled by Arbitration. </p>
                          <p> c.	The dispute shall be referred to a Sole Arbitrator to be mutually appointed by the Company and the Suer. The Arbitration shall be conducted in terms of the Rules of the Indian Council of Arbitration. The seat and venue of the Arbitration will be Noida. </p>
                          <p> d.	All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time. </p>
                          <p> e.	The arbitration award will be final and binding on the Parties, and each Party will bear its costs of arbitration and equally share the fees of the arbitrator unless the arbitrary tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award. </p>
                          <p> f.	Nothing contained in these Terms and Conditions shall prevent the Company from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard MyBattle11 Platform’s interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of the Company to pursue any remedy for monetary damages through the arbitration described herein. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">   Disclaimers  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a.	MyBattle11 Platform and the Company have no association, affiliation or claims in any manner or capacity to be linked with the following directly or indirectly: </p>
                          <p> i)	International Cricket Council ("ICC") or any national cricket board or team; </p>
                          <p> ii)	Board of Control for Cricket in India ("BCCI"), the Indian Premier League ("IPL") or any IPL franchises; any other domestic cricket tournament/league, or tournament franchise/team (unless specifically declared as such); </p>
                          <p> b.	To the extent permitted under law, neither MyBattle11 Platform nor its directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material. </p>
                          <p> c.	To the extent permitted under law, MyBattle11 Platform shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, MyBattle11 Platform Services or sites, cancellation of competition and prizes. MyBattle11 Platform disclaims any responsibility for, and if a User pays for access to one of MyBattle11 Platform Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by MyBattle11 Platform’s maintenance on the servers or the technology that underlies our sites, failures of MyBattle11 Platform Services providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, MyBattle11 Platform does not provide any warranty as to the content on MyBattle11 Platform(s). MyBattle11 Platform(s) content is distributed on an “as is, as available” basis. </p>
                          <p> d.	Any material accessed, downloaded or otherwise obtained through MyBattle11 Platform is done at the User’s discretion, competence, acceptance and risk, and the User will be solely responsible for any Potential damage to User’s computer system or loss of data that results from a User’s download of any such material. </p>
                          <p> e.	MyBattle11 Platform shall make best endeavours to ensure that MyBattle11 Platform(s) is error-free and secure, however, neither MyBattle11 Platform nor any of its partners, licensor's or associates makes any warranty that: </p>
                          <p> f.	• MyBattle11 Platform(s) will meet Users' requirements, </p>
                          <p> • MyBattle11 Platform(s) will be uninterrupted, timely, secure, or error free </p>
                          <p> • The results that may be obtained from the use of MyBattle11 Platform(s) will be accurate or reliable; and </p>
                          <p> g.	In case MyBattle11 Platform discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, MyBattle11 Platform reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, MyBattle11 Platform agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same. </p>
                          <p> h.	To the extent permitted under law, neither MyBattle11 Platform nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages. </p>
                          <p> i.	Any MyBattle11 Platform Services, events or Pool(s) being hosted or provided, or intended to be hosted or provided by MyBattle11 Platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated without any prior notice, if such permission or authority is either not obtained or denied either before or after the availability of the relevant MyBattle11 Platform Services, events or Pool(s) are hosted or provided. </p>
                          <p> j.	To the extent permitted under law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">   General Provisions  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a. 	Notice: All notices served by the Company shall be provided via email to Your account or as a general notification on the Application. Any notice to be provided to the Company should be sent to <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a> </p>
                          <p> b. 	Interpretation: MyBattle11 Platform reserves the right to determine, clarify the intent behind the terms and their inter-se hierarchy. </p>
                          <p> c. 	Entire Agreement: The Terms, along with the Privacy Policy, and any other guidelines made applicable to MyBattle11 Platform from time to time, constitute the entire agreement between the Company and You with respect to Your access to or use of the Application, Website and the Services thereof. </p>
                          <p> d. 	Assignment: You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted hereunder to any third party. The Company's rights under the Terms are freely transferable by the Company to any third parties without the requirement of seeking Your consent. </p>
                          <p> e. 	Severability: If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect. </p>
                          <p> f. 	Waiver: Any failure by the Company to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by the Company of that provision or right. </p>
                          <p> g. 	Force Majeure: MyBattle11 Platform shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond MyBattle11 Platform’s control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labour or materials or any cancellation of any cricket match to which a Pools relates. In such circumstances, MyBattle11 Platform shall also be entitled to cancel any related Pools(s) and to process an appropriate refund for all Participants at its discretion. </p>
                          <p> h. 	Relationship: You acknowledge that your participation on MyBattle11 Platform, does not make confer any rights beyond that of a User including but not limited to you being considered as an employee or agency or partnership or joint venture or franchise of the Company. </p>
                          <p> i. 	The Company provides these terms so that you are aware of the terms that apply to your use of MyBattle11 Platform. You acknowledge that, the Company has given you a reasonable opportunity to review these Terms and that You have agreed to them. </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">  Feedback   </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          <p> a. 	Any feedback from User relating to the same is most welcome and Company reserves the right and discretion to take any action in this regard. While the Company has made efforts to train the personnel engaged in the sales and services relating to its products to enable quality control, it makes no warranties or representations whatsoever regarding the quality and competence of such personnel and would not be responsible for any deviant behaviour of any such personnel. </p>
                          <p> b. 	Any feedback you provide with respect to MyBattle11 Platform shall be deemed to be non-confidential. MyBattle11 Platform shall be free to use such information on an unrestricted basis. </p>
                          <p> c. 	Further, by submitting the feedback, you represent and warrant that  </p>
                          <p> (i) 	your feedback does not contain confidential or proprietary information of you or of third parties;  </p>
                          <p> (ii) 	the company is not under any obligation of confidentiality, express or implied, with respect to the feedback;  </p>
                          <p> (iii) 	MyBattle11 Platform may have something similar to the feedback already under consideration or in development; and  </p>
                          <p> (iv) 	you are not entitled to any compensation or reimbursement of any kind from the Company for the feedback under any circumstances, unless specified </p>

                          </div>
                        </div>
                      </li>


                      <li>
                        <h3 id="MyBattle11">   Customer Service  </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                          MyBattle11 Platform is committed to making fantasy sports easy and effortless for its Users. We make all best endeavours to provide you with a pleasant experience. In the unlikely event that you face any issues, please contact us at <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a>
                          </div>
                        </div>
                      </li>

                      <li>
                        <h3 id="MyBattle11">   Changes to These Terms and Conditions    </h3>
                        <div class="t_c_content">
                          <div class="pdLt20">
                              <p> (a. 	As the laws and MyBattle11 Platform evolves, the terms may need to evolve as well users are advised to review this Policy regularly for any changes, as continued use is deemed approval of all changes. MyBattle11 Platform reserves the right, at its sole discretion, to modify or replace these Terms and all allied, ancillary, interlinked policies and guidelines whenever required. The upload of the revised Terms on MyBattle11 Platform itself denotes communication and disclosure of the same. </p>
                              <p> (b. 	Additionally, MyBattle11 Platform may, at its sole discretion may send a notification of the updated Terms to user through their User Account on the platform or their registered email address associated with the User account. </p>
                              <p> (c.	In case a User does not agree to the new terms, in whole or in part, the User may contact MyBattle11 Platform at <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a> for the same else the User may choose to cease usage of MyBattle11 Platform immediately. By continuing to access or use the Services offered by MyBattle11 Platform, after those revisions become effective, the user agrees to be bound by the revised terms. </p>
                          </div>
                        </div>
                      </li>  
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main >
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
