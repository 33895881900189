const appUrl = "https://admin.mybattle11.com";

export const ApiConfig = {
  getPointstest: "PointsTBL/getPointstest",
  getPoints: "PointsTBL/getPoints",
  getPointst20: "PointsTBL/getPointst20",
  getPointsODI: "PointsTBL/getPointsODI",
  getPaymentStatus: "addpayment/status/65f17b0ca132d20be1c51789_f973e8",
  

  

  // ................... //
  appUrl: `${appUrl}/`,
  paymentUrl: "https://app.mybattle11.com/"
}
