import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const ComingSoon = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div> 
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}
       
     

      <main id="main">
       
        <section className="coming_soon counts" >
            <div className="container" >
                <div className="d-flex flex-driection-column align-items-center justify-content-center" >
                  <img src="img/coming_soon.svg" className="img-fluid" alt="" />
                  <a href="/" className="btn px-5 mt-5 btn-dark btn-black" > Back to Home</a>
                </div>
            </div>
        </section>
         
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

       
      {/* <div id="preloader"></div> */}
    </>
  );
};
