// import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const AboutMobile = () => {
  return (
    <>   

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>About Us</h1>
        </div>
      </section>

      <main id="main">
       
      <section class="inner-page">
          <div class="container">
            <div class="content">
              <div>
                <h3 className="mb-4" >Welcome to "MyBattle11" </h3>
                <p class="text-justify">
                  <strong  > At MyBattle11,</strong>
                  we aspire to create a gaming universe for fantasy sports that captivates players with immersive experiences and thrilling challenges. We believe in the power of gaming to inspire, entertain, earn, and compete among other individuals across the globe by showing your skills and knowledge. Our team of passionate developers set out on a quest to bring to life a fantasy world that transcends boundaries. With a shared love for gaming and a commitment to creativity, SPODS Technologies Private Limited launched MyBattle11.


                </p>
                <br />
                <h3>Contact us</h3>
                <p class="text-justify">
                  Have questions, suggestions, or just want to share your epic victories? Reach out to us at <a className="text-decoration-underline" href="mailto:support@mybattle11.com" >support@mybattle11.com</a>. Your feedback fuels our quest to make MyBattle11 an unforgettable gaming experience.
                </p>
                <p>
                <br/>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>    
    </>
  );
};
