import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>
          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.mybattle11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section class="hero p-0 ">
        <div class="hero-main">
          <img
            src="img/hero-bg.png"
            class="img-fluid w-100 desktop-hero d-none d-sm-none d-md-block"
            alt=""
          />
          <img
            src="img/hero-mobile-banner.png"
            class="img-fluid w-100 desktop-hero d-block d-sm-block d-md-none"
            alt=""
          />
        </div>
      </section>
      <main id="main">
        <section id="counts" class="counts">
          <div class="container">
            <div class="row" data-aos="fade-up">
              <div class="col-lg-3 col-md-6">
                <div class="count-box">
                  <i class="bi bi-emoji-smile"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    1.5 MILLION+
                  </span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div class="count-box">
                  <i class="ri-currency-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    125 CRORES+
                  </span>
                  <p>Cash Winnings</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-focus-2-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="1463"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    25 LAKHS+
                  </span>
                  <p>Contests Played</p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div class="count-box">
                  <i class="ri-gift-line"></i>
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="15"
                    data-purecounter-duration="1"
                    class="purecounter"
                  >
                    10 THOUSAND+
                  </span>
                  <p>Giveaway</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= About Section ======= --> */}
        <section id="Downloading" class="Downloading pb-0">
          <div class="container">
            <div class="section-title aos-init aos-animate">
              <h2>Downloading the</h2>
              <p>MyBattle11 App is Easy!</p>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">1</span>
                    <img src="img/install01.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open www.mybattle11.com &amp; click on{" "}
                    <strong>“Download for Android”</strong> button
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">2</span>
                    <img src="img/install02.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Open mybattle11.com &amp; Wait for the apk to download
                    & click on<strong>“Open”</strong>
                  </h4>
                </div>
              </div>

              <div class="col-md-4">
                <div class="howToinstallmain">
                  <div class="howToinstall-card">
                    <span class="number-icon">3</span>
                    <img src="img/install03.png" class="img-fluid" alt="" />
                  </div>
                  <h4>
                    Click <strong>“Allow for this Source”</strong> when prompted
                    & then click on Install
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title aos-init aos-animate">
                  <h2>Enjoy Superfast Winnings with</h2>
                  <p> Super-easy Gaming</p>
                </div>
              </div>

              <div
                class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                data-aos="fade-right"
              ></div>

              <div
                class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
                data-aos="fade-left"
              >
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
                  <div class="icon">
                    <i class="">1</i>
                  </div>
                  <h4 class="title">
                    <a href="">Select A Match</a>
                  </h4>
                  <p class="description">
                    Choose an upcoming match that you want to play{" "}
                  </p>
                </div>
                <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon">
                    <i class="">2</i>
                  </div>
                  <h4 class="title">
                    <a href=""> Create Team</a>
                  </h4>
                  <p class="description">
                    Use your skills to pick the right players
                  </p>
                </div>

                <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
                  <div class="icon">
                    <i class="">3</i>
                  </div>
                  <h4 class="title">
                    <a href="">Join Contest</a>
                  </h4>
                  <p class="description">
                    Choose between different contests and win money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="welcome-sec pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 order-md-2">
                <div class="content">
                  <div class="heading">
                    <h2>
                      A Super <span class="head-text">Welcome Bonus</span>
                    </h2>
                    <h6>DOWNLOAD &amp; REGISTER TO GET</h6>
                    <h5>
                      <span>₹100</span> Sign up bonus
                    </h5>
                  </div>
                  <div class="welcome_btn mt-4 mb-3">
                    <a href="#">
                      <img
                        src="img/andrid-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                    <a href="#">
                      <img
                        src="img/apple-btn.png"
                        class="img-fluid w-100"
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    <small>
                      *This game involves an element of financial risk and may
                      be addictive. please play responsibly and at your own
                      risk.
                    </small>
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="welcome_img">
                  <img src="img/about-img.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

 



        {/* <!-- ======= F.A.Q Section ======= --> */}
        <section id="faq" class="faq section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              {/* <h2>F.A.Q</h2> */}
              <p>Frequently Asked Questions</p>
            </div>

            <div class="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What is Fantasy Cricket?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    class="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    class="collapsed"
                  >
                    How To Play Fantasy Cricket and Win Cash Daily?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on MyBattle11 , you need to go through the below steps:
                      <br /> -Download the MyBattle11 app & install it on
                      your mobile.
                      <br /> -Make a registration via your mobile number or
                      email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br /> -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br /> -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    class="collapsed"
                  >
                    What is IPL Fantasy League?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      IPL fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    class="collapsed"
                  >
                    How to Play IPL Fantasy?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on MyBattle11, you need to go through the below steps:
                      <br />
                      -Download the MyBattle11 app & install it on your
                      mobile.
                      <br />
                      -Make a registration via your mobile number or email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br />
                      -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br />
                      -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    class="collapsed"
                  >
                    Beyond Cricket - Fantasy Football And Much More
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      MyBattle11 is the most trusted and secured online
                      fantasy sports gaming platform that strictly follows
                      fantasy sports and gaming rules & regulations and runs OFS
                      contests based on fair game policy. Security is our
                      priority and we are very much committed to providing
                      secure services by safeguarding user’s personal
                      information.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <!-- End F.A.Q Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
  
      </main >
      <Footer />
      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
