import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

export const Faq = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" class="d-flex align-items-center header-transparent ">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
            {/* <!-- <h1><a href="index.html"><span>Bootslander</span></a></h1> -->
        <!-- Uncomment below if you prefer to use an image logo --> */}
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />
            </Link>
          </div>

          {/* <nav id="navbar" class="navbar">
            <ul>
              <li>
                <a class="nav-link btn btn-primary scrollto" href="https://admin.mybattle11.com/downloads">
                  {" "}
                  <i class="ri-download-fill me-2"></i> Download App
                </a>
              </li>
            </ul>
          </nav> */}

          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Faq   </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Faq  </li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page faq section-bg">
          <div class="container">
            <div class="content">
              <div class="TermsCondtns">
                  <div class="TermsCondtnsBox">

                  <div class="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <a
                    data-bs-toggle="collapse"
                    class="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What is Fantasy Cricket?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    class="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    class="collapsed"
                  >
                    How To Play Fantasy Cricket and Win Cash Daily?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on MyBattle11 , you need to go through the below steps:
                      <br /> -Download the MyBattle11 app & install it on
                      your mobile.
                      <br /> -Make a registration via your mobile number or
                      email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br /> -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br /> -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    class="collapsed"
                  >
                    What is IPL Fantasy League?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      IPL fantasy cricket is one of the biggest fantasy cricket
                      tournaments played by cricket lovers during the IPL
                      season.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    class="collapsed"
                  >
                    How to Play IPL Fantasy?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      For playing fantasy cricket & fantasy football on MyBattle11, you need to go through the below steps:
                      <br />
                      -Download the MyBattle11 app & install it on your
                      mobile.
                      <br />
                      -Make a registration via your mobile number or email id.
                      <br />
                      -Select a sport.
                      <br />
                      -Choose a contest from that picked sport.
                      <br />
                      -Pick real players and make your best team.
                      <br />
                      -You will be a winner if the total point of your team is
                      highest than other teams.
                      <br />
                      -Thereafter, you will be getting a real cash prize.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    class="collapsed"
                  >
                    Beyond Cricket - Fantasy Football And Much More
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    class="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      MyBattle11 is the most trusted and secured online
                      fantasy sports gaming platform that strictly follows
                      fantasy sports and gaming rules & regulations and runs OFS
                      contests based on fair game policy. Security is our
                      priority and we are very much committed to providing
                      secure services by safeguarding user’s personal
                      information.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
                  
                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
